import logo from './logo.svg';
import './App.css';
import Nav from './Nav';

function App() {
  return (
    <div className='App'>
      <Nav className='Nav'/>
    </div>
  );
}

export default App;
